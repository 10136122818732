import { EmbeddedLayout, LbLogoLoading } from "@lb/frontend";
import {
  accessDeniedClientUrl,
  dashboardClientUrls,
  departmentClientUrls,
  maintenanceModeClientUrl,
  settingsClientUrls,
  usersClientUrls,
  vendorsClientUrls,
} from "@lb/utils";
import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Maintenance from "./pages/Maintenance";
import { Box } from "@mui/material";

const SettingsLayout = lazy(() => import("./pages/Settings"));
const OrganizationSettings = lazy(() => import("./pages/Settings/Organization/Details"));
const OrganizationNotifications = lazy(() => import("./pages/Settings/Organization/Notifications"));
const Profile = lazy(() => import("./pages/Settings/Profile"));
const Preferences = lazy(() => import("./pages/Settings/Profile/Preferences"));
const Security = lazy(() => import("./pages/Settings/Profile/Security"));
const Notifications = lazy(() => import("./pages/Settings/Profile/Notifications"));

const VendorSummary = lazy(() => import("./pages/Vendors/Summary"));
const Vendors = lazy(() => import("./pages/Vendors"));
const AddVendor = lazy(() => import("./pages/Vendors/RecordManagement"));
const EditVendor = lazy(() => import("./pages/Vendors/RecordManagement"));
const InviteVendor = lazy(() => import("./pages/Vendors/Invite"));
const VendorMatrix = lazy(() => import("./pages/Vendors/Matrix"));
const VendorMatrixCreateEdit = lazy(() => import("./pages/Vendors/Matrix/CreateEdit"));

const AddBulkVendors = lazy(() => import("./pages/Vendors/Bulk/Add"));
const BulkVendors = lazy(() => import("./pages/Vendors/Bulk"));
const BulkVendorsSummary = lazy(() => import("./pages/Vendors/Bulk/Summary"));

const InviteUsers = lazy(() => import("./pages/Users/Invite"));
const AddEditeUsers = lazy(() => import("./pages/Users/RecordManagement"));
const RolesPermissions = lazy(() => import("./pages/Users/RolesPermissions"));
const Users = lazy(() => import("./pages/Users"));
const UsersSummary = lazy(() => import("./pages/Users/Summary"));
const UsersMatrix = lazy(() => import("./pages/Users/Matrix"));
const UsersMatrixCreateEdit = lazy(() => import("./pages/Users/Matrix/CreateEdit"));

const Departments = lazy(() => import("./pages/Departments"));
const AddEditDepartment = lazy(() => import("./pages/Departments/AddEdit"));

const Dashboard = lazy(() => import("./pages/Dashboard"));
const HomeDashboard = lazy(() => import("./pages/Dashboard/MyDashboard"));
const IAMDashboard = lazy(() => import("./pages/Dashboard/IAM"));
const CLMDashboard = lazy(() => import("./pages/Dashboard/CLM"));
const DRMDashboard = lazy(() => import("./pages/Dashboard/DRM"));

const Colors = lazy(() => import("./pages/Settings/Organization/Details/Colors"));

const AppLayout = lazy(() => import("./components/AppLayout"));

const AccessDenied = lazy(() => import("./pages/AccessDenied"));

const router = (permissions) =>
  createBrowserRouter([
    {
      path: maintenanceModeClientUrl.base,
      element: <Maintenance />,
    },
    {
      path: "/embedded",
      element: <EmbeddedLayout />,
      children: [
        {
          path: `${vendorsClientUrls.slug}/${vendorsClientUrls.edit.slug}`,
          element: (
            <Box px={3}>
              <EditVendor isEmbedded />
            </Box>
          ),
        },
        {
          path: `${usersClientUrls.slug}/${usersClientUrls.edit.slug}`,
          element: (
            <Box px={3}>
              <AddEditeUsers isEmbedded />
            </Box>
          ),
        },
        {
          path: "organization-colors",
          element: <Colors isEmbedded />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<LbLogoLoading />}>
          <AppLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={dashboardClientUrls.base} replace />,
        },
        {
          path: accessDeniedClientUrl,
          element: <AccessDenied />,
        },
        {
          path: dashboardClientUrls.slug,
          element: <Dashboard />,
          children: [
            {
              index: true,
              element: <HomeDashboard />,
            },
            {
              path: dashboardClientUrls.iam.slug,
              element: permissions?.iam?.dashboard ? <IAMDashboard /> : <AccessDenied />,
            },
            {
              path: dashboardClientUrls.clm.slug,
              element: permissions?.iam?.dashboard ? <CLMDashboard /> : <AccessDenied />,
            },
            {
              path: dashboardClientUrls.drm.slug,
              element: permissions?.iam?.dashboard ? <DRMDashboard /> : <AccessDenied />,
            },
          ],
        },
        {
          path: settingsClientUrls.base,
          element: <SettingsLayout />,
          children: [
            {
              index: true,
              element: <Navigate to={settingsClientUrls.profile.slug} />,
            },
            {
              path: settingsClientUrls.profile.slug,
              children: [
                {
                  index: true,
                  element: <Navigate replace to={settingsClientUrls.profile.details.base} />,
                },
                {
                  path: settingsClientUrls.profile.details.slug,
                  element: <Profile />,
                },
                {
                  path: settingsClientUrls.profile.preferences.slug,
                  element: <Preferences />,
                },
                {
                  path: settingsClientUrls.profile.notification.slug,
                  element: <Notifications />,
                },
                {
                  path: settingsClientUrls.profile.security.slug,
                  element: <Security />,
                },
              ],
            },
            {
              path: settingsClientUrls.organization.slug,
              children: [
                {
                  index: true,
                  element: permissions?.iam?.settings?.organization ? (
                    <OrganizationSettings />
                  ) : (
                    <AccessDenied />
                  ),
                },
                {
                  path: settingsClientUrls.organization.notifications.slug,
                  element: <OrganizationNotifications />,
                },
              ],
            },
          ],
        },

        {
          path: usersClientUrls.base,
          children: [
            {
              path: usersClientUrls.base,
              element: permissions?.iam?.users ? <Users /> : <AccessDenied />,
              children: [
                {
                  path: usersClientUrls.invite.slug,
                  element: permissions?.iam?.users?.invite ? (
                    <InviteUsers />
                  ) : (
                    <Navigate to={usersClientUrls.base} />
                  ),
                },
              ],
            },
            {
              path: usersClientUrls.add.slug,
              element: permissions?.iam?.users?.add ? <AddEditeUsers /> : <AccessDenied />,
            },
            {
              path: usersClientUrls.edit.slug,
              element: permissions?.iam?.users?.edit ? <AddEditeUsers /> : <AccessDenied />,
            },
            {
              path: usersClientUrls.summary.slug,
              element: <UsersSummary />,
            },
            {
              path: "matrix",
              element: <Navigate replace to={"/matrix/reviewer"} />,
            },
            {
              path: usersClientUrls.matrix.slug,
              children: [
                {
                  index: true,
                  element: permissions?.iam?.users?.reviewerMatrix ? (
                    <UsersMatrix />
                  ) : (
                    <AccessDenied />
                  ),
                },
                {
                  path: usersClientUrls.matrix.create.slug,
                  element: permissions?.iam?.users?.reviewerMatrix?.create ? (
                    <UsersMatrixCreateEdit />
                  ) : (
                    <AccessDenied />
                  ),
                },
                {
                  path: usersClientUrls.matrix.update.slug,
                  element: permissions?.iam?.users?.reviewerMatrix?.edit ? (
                    <UsersMatrixCreateEdit />
                  ) : (
                    <AccessDenied />
                  ),
                },
              ],
            },
          ],
        },

        {
          path: usersClientUrls.rolesPermissions.base,
          element: permissions?.iam?.users?.roles ? <RolesPermissions /> : <AccessDenied />,
        },
        {
          path: departmentClientUrls.slug,
          element: permissions?.iam?.departments ? <Departments /> : <AccessDenied />,
          children: [
            {
              path: departmentClientUrls.add.slug,
              element: permissions?.iam?.departments?.add ? (
                <AddEditDepartment />
              ) : (
                <Navigate to={departmentClientUrls.base} />
              ),
            },
            {
              path: departmentClientUrls.edit.slug,
              element: permissions?.iam?.departments?.edit ? (
                <AddEditDepartment />
              ) : (
                <AccessDenied />
              ),
            },
          ],
        },
        {
          path: vendorsClientUrls.base,
          children: [
            {
              path: vendorsClientUrls.base,
              element: permissions?.iam?.vendors ? <Vendors /> : <AccessDenied />,
              children: [
                {
                  path: vendorsClientUrls.invite.slug,
                  element: permissions?.iam?.vendors?.invite ? (
                    <InviteVendor />
                  ) : (
                    <Navigate to={vendorsClientUrls.base} />
                  ),
                },
              ],
            },
            {
              path: vendorsClientUrls.add.slug,
              element: permissions?.iam?.vendors?.add ? <AddVendor /> : <AccessDenied />,
            },
            {
              path: vendorsClientUrls.edit.slug,
              element: permissions?.iam?.vendors?.edit ? <EditVendor /> : <AccessDenied />,
            },
            {
              path: vendorsClientUrls.bulk.slug,
              children: [
                {
                  path: vendorsClientUrls.bulk.base,
                  element: permissions?.iam?.vendors?.bulk ? <BulkVendors /> : <AccessDenied />,
                  children: [
                    {
                      path: vendorsClientUrls.bulk.add.slug,
                      element: permissions?.iam?.vendors?.bulk?.add ? (
                        <AddBulkVendors />
                      ) : (
                        <AccessDenied />
                      ),
                    },
                  ],
                },
                {
                  path: vendorsClientUrls.bulk.summary.slug,
                  element: <BulkVendorsSummary />,
                },
              ],
            },
            {
              path: vendorsClientUrls.summary.slug,
              element: <VendorSummary />,
            },
            {
              path: "matrix",
              element: <Navigate replace to={"/matrix/reviewer"} />,
            },
            {
              path: vendorsClientUrls.matrix.slug,
              children: [
                {
                  index: true,
                  element: permissions?.iam?.vendors?.reviewerMatrix ? (
                    <VendorMatrix />
                  ) : (
                    <AccessDenied />
                  ),
                },
                {
                  path: vendorsClientUrls.matrix.create.slug,
                  element: permissions?.iam?.vendors?.reviewerMatrix?.create ? (
                    <VendorMatrixCreateEdit />
                  ) : (
                    <AccessDenied />
                  ),
                },
                {
                  path: vendorsClientUrls.matrix.update.slug,
                  element: permissions?.iam?.vendors?.reviewerMatrix?.edit ? (
                    <VendorMatrixCreateEdit />
                  ) : (
                    <AccessDenied />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

export default router;
