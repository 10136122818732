import { LbLogoLoading, usePermissions } from "@lb/frontend";
import { RouterProvider } from "react-router-dom";
import router from "./router";

export default function App() {
  const { permissions } = usePermissions();
  
  return (
    <RouterProvider {...{ router: router(permissions), fallbackElement: <LbLogoLoading /> }} />
  );
}
